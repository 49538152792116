.slideDown {
    animation: slideInDown;
    animation-duration: 2s;
}

.slideUp {
    animation: slideInUp;
    animation-duration: 2s;
}

.slideLeft {
    animation: slideInLeft;
    animation-duration: 2s;
}

.slideRight {
    animation: slideInRight;
    animation-duration: 2s;
}
.fadeIn {
    animation: fadeIn;
    animation-duration: 2s;
}
