@import '../../base.scss';

.header__section {
    background-color: white;
    width: 100%;
}
.header__button {
    font-size: 16px;
    font-weight: 500;
    height: 50px;
    padding: 2px 15px;
    width: 200px;
}

.header__main__text {
    margin-top: 0;
    font-size: 50px;
    font-weight: 700;
    line-height: 90%;
    // text-align: left;
    color: $primary-color-dark;
}
.header__secondary__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    // text-align: left;
    color: $primary-color-dark;
    // width: 80%;
}

.header__avatar {
    height: 500px;

    @include media-breakpoint-down(lg) {
        margin-top: 50px;
        height: 400px;
        width: 400px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 0px;
        height: 400px;
        width: 400px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }
}

@include media-breakpoint-down(lg) {
    .header__button__section {
        text-align: center;
    }
}

@include media-breakpoint-up(xl) {
    .header__main__text {
        text-align: left;
        margin-top: 90px;
    }
    .header__secondary__text {
        text-align: left;
        width: 80%;
    }
    .header__avatar__desktop {
        display: block;
    }

    .header__avatar__mobile {
        display: none;
    }
}
@include media-breakpoint-up(lg) {
    .header__section {
        margin-top: 80px;
    }
    .header__main__text {
        text-align: left;
        margin-top: 60px;
    }
    .header__secondary__text {
        text-align: left;
        width: 80%;
    }
    .header__avatar__desktop {
        display: block;
    }

    .header__avatar__mobile {
        display: none;
    }
}
@include media-breakpoint-down(lg) {
    .header__main__text {
        text-align: center;
    }
    .header__secondary__text {
        text-align: center;
    }
    .header__avatar__desktop {
        display: none;
    }

    .header__avatar__mobile {
        display: block;
    }

    .remove__lg {
        display: none;
    }
}
@include media-breakpoint-down(md) {
    .remove__md {
        display: none;
    }
    .add__mobile {
        margin-left: 2px;
    }
}
@include media-breakpoint-down(sm) {
    .remove__sm {
        display: none;
    }
    .add__mobile {
        margin-left: 2px;
    }
}

.box {
    width: 100%;
    height: 500px;
    background-color: red;
}

.brand__image {
    height: 22px;
    margin-left: 0;

    @media screen and (max-width: 320px) {
        height: 15px;
    }
}

.brand__text {
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
}

.third__section {
    width: 100%;
    padding-bottom: 100px;
    background-color: $accent-color;
    margin-top: 100px;
    @include media-breakpoint-up(sm) {
        border-radius: 10px;
    }
}

.third__section__image {
    height: 320px;
    @include media-breakpoint-down(xl) {
        margin-top: 50px;
        height: 300px;
        width: 400px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    @include media-breakpoint-down(lg) {
        margin-top: 0px;
        height: 400px;
        width: 400px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }
    @include media-breakpoint-down(md) {
        margin-top: 0px;
        height: 300px;
        width: 500px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 0px;
        height: 170px;
        width: 400px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }
    @media screen and (max-width: 320px) {
        margin-top: 0px;
        height: 150px;
        width: 300px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }
}

.third__section__header {
    margin-top: 0;
    font-size: 35px;
    font-weight: 700;
    line-height: 90%;

    // text-align: left;
    color: $primary-color-dark;

    @include media-breakpoint-up(lg) {
        width: 70%;
    }
}
.third__section__header2 {
    margin-top: 100px;
    font-size: 15px;
    font-weight: 600;
    line-height: 90%;
    @include media-breakpoint-down(lg) {
    }
    // text-align: left;
    color: $primary-color-dark;
}
.third__section__secondary {
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    // text-align: left;
    color: $primary-color-dark;
    @include media-breakpoint-up(lg) {
        width: 80%;
    }

    // width: 80%;
}
.third__section__secondary2 {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: $primary-color-dark;
    // text-align: left;
}
.third__section__link {
    color: $primary-color;
    font-weight: 600;

    &:hover {
        .third__section__icon {
            animation: slideOutRight;
            animation-duration: 1s;
        }
    }
}

.margin__custom__1 {
    margin-top: 100px;
}

.fourth__section {
    background-color: $accent-color-dark;
    width: 100%;
    padding-bottom: 100px;
    margin-top: 100px;
    @include media-breakpoint-up(sm) {
        border-radius: 10px;
    }
}
.fourth__section__header {
    margin-top: 0;
    font-size: 35px;
    font-weight: 700;
    line-height: 90%;

    // text-align: left;
    color: $primary-color-dark;

    @include media-breakpoint-up(lg) {
        width: 70%;
    }
}
.fourth__section__header2 {
    margin-top: 100px;
    font-size: 15px;
    font-weight: 600;
    line-height: 90%;
    @include media-breakpoint-down(lg) {
    }
    // text-align: left;
    color: $primary-color-dark;
}
.fourth__section__secondary {
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    // text-align: left;
    color: $primary-color-dark;
    @include media-breakpoint-up(lg) {
        width: 80%;
    }

    // width: 80%;
}
.fourth__section__secondary2 {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: $primary-color-dark;
    // text-align: left;
}
.fourth__section__link {
    color: $primary-color;
    font-weight: 600;

    &:hover {
        .third__section__icon {
            animation: slideOutRight;
            animation-duration: 1s;
        }
    }
}

.fifth__section {
    width: 100%;
    padding-bottom: 100px;
    background-color: $primary-color-dark;

    margin-top: 100px;

    @include media-breakpoint-up(sm) {
        border-radius: 10px;
    }
}

.fifth__section__header {
    margin-top: 0;
    font-size: 35px;
    font-weight: 700;
    line-height: 90%;
    color: white;

    @include media-breakpoint-up(lg) {
        width: 70%;
    }
}
.fifth__section__header2 {
    margin-top: 100px;
    font-size: 15px;
    font-weight: 600;
    line-height: 90%;
    @include media-breakpoint-down(lg) {
    }

    color: white;
}
.fifth__section__secondary {
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;

    color: white;
    @include media-breakpoint-up(lg) {
        width: 80%;
    }
}
.fifth__section__secondary2 {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: white;
    // text-align: left;
}
.fifth__section__link {
    color: $misc-color;
    font-weight: 600;

    &:hover {
        .third__section__icon {
            animation: slideOutRight;
            animation-duration: 1s;
        }
    }
}

.sixth__section__header {
    margin-top: 100px;
    font-size: 35px;
    font-weight: 700;
    line-height: 90%;
    width: 100%;
    text-align: center;
    color: $primary-color-dark;
}

.sixth__section__secondary {
    font-size: 17px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 80px;
    text-align: center;
    color: $primary-color-dark;
}

.seventh__section__icon {
    font-size: 50px;
    text-align: center !important;
    color: $primary-color-dark;
}

.seventh__section__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 90%;
    width: 100%;
    text-align: center;
    color: $primary-color-dark;
}

.seventh__section__text {
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    color: $primary-color-dark;
}

.seventh__icon1 {
    font-size: 15px;
    color: #ffe3b1;
}
.seventh__icon2 {
    font-size: 15px;
    color: $accent-color-dark;
}

.eighth__section__header {
    margin-top: 100px;
    font-size: 35px;
    font-weight: 700;
    line-height: 110%;
    width: 90%;
    text-align: left;
    color: $primary-color-dark;

    @include media-breakpoint-down(xl) {
        font-size: 30px;
        width: 100%;
    }
    @include media-breakpoint-down(lg) {
        text-align: center;
        width: 100%;
    }
}

.eighth__section__box {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    background-color: $primary-color-dark;
    color: white;
    border-radius: 5px;
}
.eighth__section__box__header {
    font-size: 20px;
    font-weight: 500;
}
.eighth__section__box__secondary {
    font-size: 15px;
    font-weight: 300;
}

.eighth__section__box__name {
    font-size: 12px;
    font-weight: 500;
}

.eighth__section__box__position {
    font-size: 12px;
    font-weight: 300;
}

.eighth__section__box__image {
    float: right;
}

.eighth__section__box.extra {
    background-color: $accent-color-darker;
}

.price__box {
    margin-bottom: 100px;
    width: 100%;
    background-color: white;
    border: 1px solid $accent-color-dark;
    color: $primary-color-dark;

    border-radius: 5px;
    padding-left: 30px;
    padding-right: 30px;

    &:hover {
        background-color: $primary-color-dark;
        color: white !important;
        animation: pulse;
        animation-duration: 1s;

        .price__button {
            background-color: $misc-color;
            border-color: $misc-color;
            color: white;
        }
    }

    .price__box__header {
        font-weight: 400;
        font-size: 20px;
    }

    .price__box__price {
        .price {
            font-size: 22px;
            font-weight: 500;
        }
    }
    .price__box__description {
        font-size: 15px;
        font-weight: 400;
    }

    .price__button {
        width: 100%;
    }
}

.ninth__Section {
    margin-top: 1rem;
    .trial__section__header {
        font-size: 35px;
        font-weight: 700;
        line-height: 110%;
        width: 90%;
        text-align: left;
        color: $primary-color-dark;
        @include media-breakpoint-down(md) {
            text-align: center;
            width: 100%;
        }
    }

    .trial__section__secondary {
        font-size: 17px;
        font-weight: 400;
        line-height: 150%;

        text-align: left;
        color: $primary-color-dark;

        @include media-breakpoint-down(md) {
            text-align: center;
            width: 100%;
        }
    }
    .trial__section__button {
        font-size: 18px;
        float: right;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        text-align: center;
        font-weight: 500;
    }
}
.tenth__Section {
    margin-top: 1rem;
    .trial__section__header {
        font-size: 35px;
        font-weight: 700;
        line-height: 110%;
        width: 90%;
        text-align: left;
        color: $primary-color-dark;
        @include media-breakpoint-down(md) {
            text-align: center;
            width: 100%;
        }
    }

    .trial__section__secondary {
        font-size: 17px;
        font-weight: 400;
        line-height: 150%;

        text-align: left;
        color: $primary-color-dark;

        @include media-breakpoint-down(md) {
            text-align: center;
            width: 100%;
        }
    }
    .trial__section__button {
        font-size: 18px;
        float: right;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        text-align: center;
        font-weight: 500;
    }
}

.footer__section {
    margin-top: 100px;
    border-top: 1px solid $accent-color-dark;

    @include media-breakpoint-down(md) {
        text-align: center;
    }
}

.back-to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
    display: none;
    background-color: $primary-color;
    color: $misc-color;

    &:hover {
        color: $misc-color;
    }
}

.ghana__flag {
    height: 10px;
}
