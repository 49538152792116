// $primary-color: #1a47ff;
// $primary-color-dark: #061a40;
// $secondary-color-dark: #243659;
// $secondary-color-light: #c9d4e2;
// $tertiary-color-light: #f4f4f4;
// $accent-color: #f8faff;
// $accent-color-dark: #d6def6;
// $accent-color-darker: #7385a8;

$primary-color: #004346;
$primary-color-dark: #172a3a;
$secondary-color-dark: #09bc8a;
$secondary-color-light: #c7f0e5;
$tertiary-color-light: #e3f8f2;
$accent-color: #e3f8f2;
$accent-color-dark: #abe9d8;
$accent-color-darker: #abe9d8;
$misc-color: #ffa04d;
