@import './colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mate+SC&display=swap');
$primary: $primary-color;
$secondary: $secondary-color-dark;
$theme-colors: (
    'primary': $primary,
    'warning': $primary,
    'secondary': $secondary
);

@import '../node_modules/bootstrap/scss/bootstrap.scss';
$font-family1: 'Inter', sans-serif;
$font-family2: 'Mate SC', serif;
body {
    margin: 0;
    font-family: $font-family1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* width */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $accent-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $primary-color-dark;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $primary-color-dark;
}
